<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <h4>Баланс на рахунках</h4>
    <div class="col-xl-6 col-sm-6 mb-xl-0">
      <div class="dropdown d-inline">
        <a
          href="javascript:;"
          class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
          data-bs-toggle="dropdown"
          id="navbarDropdownMenuLink2"
        >
          🗓 {{ displaySelectedDateRange() }}
        </a>
        <ul
          class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
          aria-labelledby="navbarDropdownMenuLink2"
          data-popper-placement="left-start"
        >
          <li v-for="(dateRange, index) in dateRanges" :key="index">
            <button
              class="dropdown-item border-radius-md"
              @click="filterByDateRange(dateRange._id)"
            >
              {{ dateRange.name }}
            </button>
          </li>
          <li>
            <hr class="horizontal dark my-2" />
          </li>
        </ul>
      </div>
      <div class="row" v-show="selectedDateRange === 'custom'">
        <div class="col-lg-3 col-sm-12 text-end">
          <flat-pickr
            v-model="customSelectedDateRange"
            id="calendarFlatpickr"
            class="form-control datetimepicker"
            :config="calendarConfig"
            ref="datePickerWrap"
          ></flat-pickr>
          <div class="mb-4"></div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-3"
        v-for="(value, key) in filterTotals()"
        :key="value.name"
      >
        <div class="card mb-4">
          <div class="card-header p-3 pt-2">
            <div
              class="icon icon-md icon-shape bg-gradient-danger shadow text-center border-radius-md mt-n4 position-absolute"
              v-if="key === 'refund'"
            >
              <i class="material-icons opacity-10">money_off</i>
            </div>
            <div
              class="icon icon-md icon-shape bg-gradient-dark shadow text-center border-radius-md mt-n4 position-absolute"
              v-else
            >
              <i class="material-icons opacity-10">{{
                value?.icon || "attach_money"
              }}</i>
            </div>

            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">{{ value.name }}</p>
              <h5 class="mb-0">{{ value.value }} грн</h5>
            </div>
          </div>
          <div class="card-footer p-1"></div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-md bg-gradient-primary"
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      @click="initModal()"
    >
      + транзакція
    </button>
    <!-- TODO: Change modal ID -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      ref="transactionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-normal" id="exampleModalLabel">
              {{
                activeTransaction ? "Редагувати транзакцію" : "Нова транзакція"
              }}
            </h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="clearTransactions()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Operations -->
            <div id="actions">
              <div class="card-body pt-0 mt-3">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade active show"
                    id="nav-spend"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div class="row mt-2">
                      <form role="form" class="text-start">
                        <div class="row">
                          <div class="w-50">Тип транзакції</div>
                          <div class="dropdown d-inline w-50">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-outline-dark dropdown-toggle"
                              data-bs-toggle="dropdown"
                              id="navbarDropdownMenuLink2"
                              :class="enableSwitchingType ? '' : 'disabled'"
                            >
                              <span
                                class="material-icons align-middle mb-1"
                                :class="getDropdownIconClass(activeTab)"
                                >{{ getDropdownIcon(activeTab) }}</span
                              >
                              {{ transactionTypes[activeTab] || "Витрата" }}
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                              aria-labelledby="navbarDropdownMenuLink2"
                              data-popper-placement="left-start"
                            >
                              <li
                                v-for="(value, key) in transactionTypes"
                                :key="key"
                              >
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  @click="tabClickHandler(key)"
                                >
                                  <span
                                    class="material-icons align-middle mb-1"
                                    :class="getDropdownIconClass(key)"
                                    >{{ getDropdownIcon(key) }}</span
                                  >
                                  <span class="text-xs"> {{ value }} </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="row" v-if="activeTab !== 'profit'">
                          <div class="w-50">З рахунку</div>
                          <div class="dropdown d-inline w-50">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-outline-dark dropdown-toggle"
                              data-bs-toggle="dropdown"
                              id="navbarDropdownMenuLink2"
                              :class="enableSwitchingType ? '' : 'disabled'"
                            >
                              {{
                                transactionOperations[expenseTransactionType] ||
                                "Готівка"
                              }}
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                              aria-labelledby="navbarDropdownMenuLink2"
                              data-popper-placement="left-start"
                            >
                              <li
                                v-for="(value, key) in transactionOperations"
                                :key="key"
                              >
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  @click="setTransaction(key, null)"
                                  >{{ value }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div class="row" v-if="activeTab !== 'expense'">
                          <div class="w-50">На рахунок</div>
                          <div class="dropdown d-inline w-50">
                            <a
                              href="javascript:;"
                              class="btn btn-sm btn-outline-dark dropdown-toggle"
                              data-bs-toggle="dropdown"
                              id="navbarDropdownMenuLink2"
                              :class="enableSwitchingType ? '' : 'disabled'"
                            >
                              {{
                                transactionOperations[profitTransactionType] ||
                                "Готівка"
                              }}
                            </a>
                            <ul
                              class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                              aria-labelledby="navbarDropdownMenuLink2"
                              data-popper-placement="left-start"
                            >
                              <li
                                v-for="(value, key) in transactionOperations"
                                :key="key"
                              >
                                <a
                                  class="dropdown-item border-radius-md"
                                  href="javascript:;"
                                  @click="setTransaction(null, key)"
                                  >{{ value }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="row">
                          <div class="w-50">Дата</div>
                          <div class="w-50">
                            <flat-pickr
                              v-model="transactionDate"
                              id="transactionFlatpickr"
                              class="form-control datetimepicker"
                              :config="transactionCalendarConfig"
                              ref="transactionDatePickerWrap"
                            ></flat-pickr>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div
                            class="input-group input-group-outline paymentInput"
                          >
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Сума грн."
                              v-model="transactionAmount"
                            />
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <div class="input-group input-group-outline">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Коментар"
                                v-model="transactionComment"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Operations finish -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal"
              @click="clearTransactions()"
            >
              закрити
            </button>
            <button
              v-if="activeTransaction"
              type="button"
              class="btn bg-gradient-danger"
              @click="deleteTransaction()"
            >
              Видалити
            </button>
            <button
              type="button"
              data-bs-dismiss="modal"
              class="btn bg-gradient-primary"
              @click="
                activeTransaction ? updateTransaction() : addTransactions()
              "
            >
              Зберегти
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h5 class="mb-0">Всі транзакції</h5>
          </div>
          <div class="table-responsive">
            <vue-good-table
              mode="remote"
              :columns="datatableColumns"
              :rows="tableData.rows"
              :row-style-class="getRowClass"
              :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
                position: 'bottom',
                perPageDropdownEnabled: false,
                nextLabel: 'наст.',
                prevLabel: 'попер.',
                rowsPerPageLabel: 'записів на сторінці',
                ofLabel: 'із',
                pageLabel: 'сторінка',
                allLabel: 'всі',
              }"
              :totalRows="tableData.totalRecords"
              v-on:page-change="onInstantMessageTopUpPageChange"
              theme="default"
              styleClass="vgt-table"
              class="table table-hover"
              responsive
              compactMode
            >
              <template #table-row="props">
                <div v-if="props.column.field === 'icon'">
                  <span
                    v-if="props.row?.serviceorder"
                    class="material-icons align-middle mb-1 fs-6"
                    @click="showServiceOrder(props.row)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Переглянути бронювання"
                    >visibility</span
                  >

                  <span
                    v-if="props.row?.isCustomTransaction"
                    class="material-icons align-middle mb-1"
                    @click="showTransactionModal(props.row)"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Редагувати транзакцію"
                    >visibility</span
                  >
                </div>
                <div v-if="props.column.field === 'paidAt'">
                  <span class="my-2 text-xs">{{
                    timestampToString(props.row?.paidAt)
                  }}</span>
                </div>
                <div v-if="props.column.field === 'transactionType'">
                  <span
                    class="material-icons align-middle mb-1 text-success"
                    v-if="props.row?.transactionType === 'profit'"
                    >north</span
                  >
                  <span
                    class="material-icons align-middle mb-1 text-danger"
                    v-if="props.row?.transactionType === 'expense'"
                    >south</span
                  >
                  <span
                    class="material-icons align-middle mb-1 text-primary"
                    v-if="props.row?.transactionType === 'transfer'"
                    >recycling</span
                  >
                  <span class="my-2 text-xs">{{
                    getTransactionType(props.row)
                  }}</span>
                </div>
                <div v-if="props.column.field === 'service' && !_isHotel()">
                  <span class="my-2 text-xs">{{
                    props.row?.services?.map((s) => s?.name).join(" ")
                  }}</span>
                </div>
                <div v-if="props.column.field === 'staff'">
                  <span class="my-2 text-xs">{{ props.row?.staff?.name }}</span>
                </div>
                <div v-if="props.column.field === 'admin'">
                  <span class="my-2 text-xs">{{
                    [props.row?.admin?.firstName, props.row?.admin?.lastName]
                      .filter((n) => !!n)
                      .join(" ")
                  }}</span>
                </div>
                <div v-if="props.column.field === 'operationType'">
                  <span class="my-2 text-xs">{{
                    getTransactionType2(props.row)
                  }}</span>
                </div>
                <div v-if="props.column.field === 'amount'">
                  <span
                    class="my-2 text-xs"
                    :class="
                      props.row?.amount >= 0 ? 'text-success' : 'text-danger'
                    "
                    >{{ props.row?.amount }}</span
                  >
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "@/constants";
import setNavPills from "@/assets/js/nav-pills.js";
import api from "../../../services/api";
import { DateTime } from "luxon";
import * as _ from "lodash";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import flatPickr from "vue-flatpickr-component";
import Spinner from "@/views/shared/Spinner.vue";
import Modal from "bootstrap/js/dist/modal";

export default {
  name: "cashActions",

  components: {
    flatPickr,
    Spinner,
  },

  async created() {},

  async mounted() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    this.company = await api.company.show();

    const staffColumn = this.datatableColumns.find((c) => c.field === "staff");
    if (staffColumn) {
      staffColumn.label = this._isHotel() ? "Номер" : "Співробітник";
    }

    const serviceColumn = this.datatableColumns.find(
      (c) => c.field === "service"
    );
    if (serviceColumn && this._isHotel()) {
      serviceColumn.hidden = true;
    }

    const defaultPeriod = this.getPeriod("currentMonth");
    this.selectedDateFrom = defaultPeriod.dateFrom;
    this.selectedDateTo = defaultPeriod.dateTo;

    await this.fetchData();
    this.dataLoaded = true;

    const modalElement = this.$refs.transactionModal;
    this.transactionModal = new Modal(modalElement, {});
  },

  data() {
    return {
      dataLoaded: false,
      isNavPillsInited: false,
      showDevFeatures: false,
      serverParams: {
        sort: [
          {
            field: "date",
            type: "desc",
          },
        ],
        page: 1,
        perPage: constants.defaultDatatablePerPage,
      },
      datatableColumns: [
        {
          label: "",
          field: "icon",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
          width: "50px",
        },
        {
          label: "Дата",
          field: "paidAt",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Тип транзакції",
          field: "transactionType",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Послуга",
          field: "service",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: null,
          field: "staff",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Адмін",
          field: "admin",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Рахунок",
          field: "operationType",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Сума",
          field: "amount",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
        {
          label: "Коментар",
          field: "comment",
          sortable: false,
          thClass: "thead-light text-center",
          tdClass: "text-xs font-weight-normal text-center",
        },
      ],
      company: {
        crmMode: "",
      },
      tableData: {
        totalRecords: 0,
        rows: [],
      },
      totals: {
        cash: {
          name: "Готівка",
          value: 0,
        },
        "card-transfer": {
          name: "Картка",
          icon: "payment",
          value: 0,
        },
        "monobank-acquiring": {
          name: "mono",
          value: 0,
        },
        "liqpay-acquiring": {
          name: "LiqPay",
          value: 0,
        },
        refund: {
          name: "Повернуто",
          value: 0,
        },
      },
      selectedDateRange: "currentMonth",
      customSelectedDateRange: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      dateRanges: [
        {
          _id: "allTime",
          name: "Весь час",
        },
        {
          _id: "currentMonth",
          name: "Поточний місяць",
        },
        {
          _id: "previousMonth",
          name: "Попередній місяць",
        },
        {
          _id: "currentWeek",
          name: "Поточний тиждень",
        },
        {
          _id: "previousWeek",
          name: "Попередній тиждень",
        },
        {
          _id: "currentYear",
          name: "Поточний рік",
        },
        {
          _id: "previousYear",
          name: "Попередній рік",
        },
        {
          _id: "today",
          name: "Сьогодні",
        },
        {
          _id: "yesterday",
          name: "Вчора",
        },
        {
          _id: "monthToDate",
          name: "Поточний місяць (до сьогодні)",
        },
        {
          _id: "yearToDate",
          name: "Поточний рік (до сьогодні)",
        },
        {
          _id: "custom",
          name: "Довільні дати",
        },
      ],
      calendarConfig: {
        mode: "range",
        dateFormat: "Y-m-d",
        maxDate: DateTime.now().toISODate(),
        locale: Ukrainian,
        inline: true,
        disableMobile: true,
        onChange: async (selectedDates) => {
          if (_.isArray(selectedDates) && selectedDates.length === 2) {
            this.selectedDateFrom = DateTime.fromJSDate(
              selectedDates[0]
            ).toISODate();
            this.selectedDateTo = DateTime.fromJSDate(
              selectedDates[1]
            ).toISODate();
            await this.fetchData();
          }
        },
      },
      transactionComment: "",
      transactionType: "",
      transactionAmount: 0,
      expenseTransactionType: "cash",
      profitTransactionType: "cash",
      transactionTypes: {
        expense: "Витрата",
        profit: "Прибуток",
        transfer: "Переказ",
      },
      transactionOperations: {
        "card-transfer": "Картка",
        cash: "Готівка",
      },
      transactionDate: DateTime.now().toISODate(),
      transactionCalendarConfig: {
        dateFormat: "Y-m-d",
        locale: Ukrainian,
        maxDate: DateTime.now().toISODate(),
        disableMobile: true,
      },
      activeTab: "expense",
      activeTransaction: null,
      transactionModal: null,
      enableSwitchingType: true,
    };
  },

  methods: {
    initModal() {
      this.clearTransactions();
      if (this.isNavPillsInited) {
        return;
      }
      this.isNavPillsInited = true;
      setTimeout(setNavPills, 500);
    },
    async fetchData() {
      const payload = {
        limit: this.serverParams.perPage,
        offset: (this.serverParams.page - 1) * this.serverParams.perPage,
        datatable: "y",
      };

      if (this.selectedDateFrom !== null) {
        payload.dateFrom = this.selectedDateFrom;
      }

      if (this.selectedDateTo !== null) {
        payload.dateTo = this.selectedDateTo;
      }

      const result = await api.payments.list(payload);

      this.tableData.totalRecords = result.totalRecords;
      this.tableData.rows = result.rows;

      Object.entries(this.totals).forEach(([key, total]) => {
        total.value = result.totalAmounts[key] || 0;
      });
    },

    async filterByDateRange(dateRange) {
      this.selectedDateRange = dateRange;

      const rangeValue = this.getPeriod(dateRange);
      this.selectedDateFrom = rangeValue.dateFrom;
      this.selectedDateTo = rangeValue.dateTo;

      // TODO: fix custom date styles
      if (dateRange !== "custom") {
        this.$refs.datePickerWrap.fp.clear();
        await this.fetchData();
      }
    },

    displaySelectedDateRange() {
      const _dateRange = this.dateRanges.find((element) => {
        return element._id === this.selectedDateRange;
      });
      return _dateRange.name;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    async onInstantMessageTopUpPageChange(params) {
      this.updateParams({ page: params.currentPage });
      await this.fetchData();
    },

    async addTransactions() {
      if (!this.transactionAmount) {
        this.$store.commit("addToast", {
          title: "Введіть суму",
          type: "error",
        });

        return;
      }

      const transactions = [];
      if (this.activeTab !== "profit") {
        transactions.push({
          paidAt: this.transactionDate,
          comment: this.transactionComment,
          amount: -this.transactionAmount,
          operationType: this.expenseTransactionType,
          transactionType: this.activeTab,
        });
      }

      if (this.activeTab !== "expense") {
        transactions.push({
          paidAt: this.transactionDate,
          comment: this.transactionComment,
          amount: this.transactionAmount,
          operationType: this.profitTransactionType,
          transactionType: this.activeTab,
        });
      }

      const result = await api.payments.addTransactions({ transactions });

      if (!result) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
        return;
      }

      this.$store.commit("addToast", {
        title: "Збережено",
      });

      await this.fetchData();
    },

    async clearTransactions() {
      this.transactionComment = "";
      this.transactionType = "";
      this.transactionAmount = 0;
      this.expenseTransactionType = "cash";
      this.profitTransactionType = "cash";
      this.activeTab = "expense";
      this.transactionDate = DateTime.now().toISODate();
      this.activeTransaction = null;
      this.enableSwitchingType = true;
    },

    _isHotel() {
      return this.company.crmMode === "hotel";
    },

    getPeriod(name) {
      const period = {
        dateFrom: null,
        dateTo: null,
      };

      switch (name) {
        case "allTime":
          period.dateFrom = null;
          period.dateTo = null;
          break;

        case "currentWeek":
          period.dateFrom = DateTime.now().startOf("week").toISODate();
          period.dateTo = DateTime.now().endOf("week").toISODate();
          break;

        case "previousWeek":
          period.dateFrom = DateTime.now()
            .minus({ week: 1 })
            .startOf("week")
            .toISODate();
          period.dateTo = DateTime.now()
            .minus({ week: 1 })
            .endOf("week")
            .toISODate();
          break;

        case "currentMonth":
          period.dateFrom = DateTime.now().startOf("month").toISODate();
          period.dateTo = DateTime.now().endOf("month").toISODate();
          break;

        case "previousMonth":
          period.dateFrom = DateTime.now()
            .minus({ month: 1 })
            .startOf("month")
            .toISODate();
          period.dateTo = DateTime.now()
            .minus({ month: 1 })
            .endOf("month")
            .toISODate();
          break;

        case "currentYear":
          period.dateFrom = DateTime.now().startOf("year").toISODate();
          period.dateTo = DateTime.now().endOf("year").toISODate();
          break;

        case "previousYear":
          period.dateFrom = DateTime.now()
            .minus({ year: 1 })
            .startOf("year")
            .toISODate();
          period.dateTo = DateTime.now()
            .minus({ year: 1 })
            .endOf("year")
            .toISODate();
          break;

        case "yesterday":
          period.dateFrom = DateTime.now()
            .minus({ days: 1 })
            .startOf("day")
            .toISODate();
          period.dateTo = period.dateFrom;
          break;

        case "monthToDate": // MTD
          period.dateFrom = DateTime.now().startOf("month").toISODate();
          period.dateTo = DateTime.now().toISODate();
          break;

        case "yearToDate": // YTD
          period.dateFrom = DateTime.now().startOf("year").toISODate();
          period.dateTo = DateTime.now().toISODate();
          break;

        case "today":
        default:
          period.dateFrom = DateTime.now().toISODate();
          period.dateTo = period.dateFrom;
          break;
      }

      return period;
    },

    tabClickHandler(tab) {
      if (!this.enableSwitchingType) {
        return;
      }

      this.activeTab = tab;

      this.expenseTransactionType = "cash";
      this.expenseTransactionType =
        tab === "transfer" ? "card-transfer" : "cash";
    },

    setTransaction(expenseType, profitType) {
      if (!this.enableSwitchingType) {
        return;
      }

      if (expenseType) {
        this.expenseTransactionType = expenseType;
        this.profitTransactionType = Object.keys(
          this.transactionOperations
        ).find((t) => t !== expenseType);
        return;
      }

      this.profitTransactionType = profitType;
      this.expenseTransactionType = Object.keys(
        this.transactionOperations
      ).find((t) => t !== profitType);
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }

      const datetime = DateTime.fromISO(input);
      return datetime.setLocale("uk-UA").toFormat("dd.MM.yyyy HH:mm:ss");
    },

    getTransactionType2(payment) {
      let operationType = payment?.operationType;
      if (payment.operationType === "refund") {
        operationType = payment?.refOriginalPayment[0]?.operationType;
      }

      return this.totals?.[operationType]?.name;
    },

    getTransactionType(payment) {
      if (payment.operationType === "refund") {
        return "Повернення";
      }
      if (payment.serviceorder) {
        return "Оплата бронювання";
      }

      if (!payment.transactionType || payment.transactionType === "") {
        return payment.amount > 0 ? "Прибуток" : "Витрата";
      }

      return this.transactionTypes[payment.transactionType];
    },

    filterTotals() {
      const result = {};

      for (const [key, value] of Object.entries(this.totals)) {
        if (
          ["monobank-acquiring", "liqpay-acquiring"].includes(key) &&
          value.value == 0
        ) {
          continue;
        }

        result[key] = value;
      }

      return result;
    },

    showServiceOrder(payment) {
      const orderId = payment?.serviceorder?._id;
      if (!orderId) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Цього бронювання не існує",
          type: "error",
        });
        return;
      }

      open(`/service-orders/${orderId}`, "_self");
    },

    newTransactionModal() {
      this.clearTransactions();
    },

    showTransactionModal(transaction) {
      this.enableSwitchingType = false;
      this.transactionAmount = Math.abs(transaction.amount);
      this.transactionComment = transaction.comment;
      this.activeTab = transaction.transactionType;
      this.transactionDate = DateTime.fromISO(transaction.paidAt).toISODate();
      this.activeTransaction = transaction._id;

      this.transactionModal.show();
    },

    async deleteTransaction() {
      const result = await api.payments.deleteTransaction(
        this.activeTransaction
      );
      if (!result) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
        return;
      }

      this.transactionModal.hide();
      this.clearTransactions();
      await this.fetchData();
      this.$store.commit("addToast", {
        title: "Видалено",
      });
    },

    async updateTransaction() {
      if (!this.transactionAmount || this.transactionAmount === 0) {
        this.$store.commit("addToast", {
          title: "Сумма транзакції не може бути 0. Спробуйте ще раз",
          type: "error",
        });

        return;
      }

      try {
        const result = await api.payments.updateTransaction(
          this.activeTransaction,
          {
            amount:
              this.activeTab === "expense"
                ? -this.transactionAmount
                : this.transactionAmount,
            comment: this.transactionComment,
            paidAt: this.transactionDate,
          }
        );

        if (!result) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
          return;
        }

        this.transactionModal.hide();
        this.clearTransactions();
        await this.fetchData();
        this.$store.commit("addToast", {
          title: "Оновлено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
        return;
      }
    },

    getDropdownIcon(value) {
      if (value === "expense") {
        return "north";
      }

      if (value === "profit") {
        return "south";
      }

      return "recycling";
    },

    getDropdownIconClass(value) {
      if (value === "expense") {
        return "text-danger";
      }

      if (value === "profit") {
        return "text-success";
      }

      return "text-primary";
    },

    getRowClass(row) {
      if (row.operationType === "refund") {
        return "slightly-red-bg";
      }
      return "";
    },
  },
};
</script>
