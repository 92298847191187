<template>
  <div class="container-fluid">
    <div class="d-sm-flex justify-content-between">
      <div v-if="company.crmMode === 'hotel'">
        <router-link
          to="/service-orders/new"
          class="btn btn-icon bg-gradient-primary"
        >
          + Бронь
        </router-link>
      </div>
      <div v-if="company.crmMode !== 'hotel'">
        <router-link
          to="/service-orders/new"
          class="btn btn-icon bg-gradient-primary"
        >
          + Запис
        </router-link>
      </div>
      <div v-if="showGroupServiceOrderButton">
        <router-link
          to="/service-orders/new?groupEnabled=1"
          class="btn btn-icon bg-gradient-primary"
        >
          + Груповий запис
        </router-link>
      </div>
      <!-- <div class="d-flex">
        <div class="dropdown d-inline">
          <a
            href="javascript:;"
            class="btn btn-outline-dark dropdown-toggle"
            data-bs-toggle="dropdown"
            id="navbarDropdownMenuLink2"
          >
            Фильтры
          </a>
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            data-popper-placement="left-start"
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Подтверждение: Ожидает</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Подтверждение: Подтверждена</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Статус: Выполнена</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Отменена</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Отменить фильтры</a
              >
            </li>
          </ul>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-body mb-3">
            <div class="d-lg-flex">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="mb-0" v-if="company.crmMode !== 'hotel'">Записи</h5>
                <h5 class="mb-0" v-if="company.crmMode === 'hotel'">Броні</h5>
                <p class="mb-0 text-sm"></p>
                <div v-if="isMobile">
                  <span
                    @click="isOpenFilter = true"
                    class="ms-lg-auto p-2 cursor-pointer"
                  >
                    <i class="material-icons" :class="{ blue: isFiltered }">
                      filter_list
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2 mx-3">
            <FilterAside
              v-if="isMobile"
              @close="isOpenFilter = false"
              :is-open="isOpenFilter"
            >
              <template #body>
                <div class="row mt-2">
                  <div class="row">
                    <div class="w-100">
                      <div class="">
                        <div class="dropdown d-inline w-100">
                          <a
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                            data-bs-auto-close="true"
                          >
                            <template v-if="filters.date"
                              >{{ filters.date }}
                            </template>
                            <template v-else> Період дат</template>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3 mx-1"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <div @click.prevent.stop="">
                              <flat-pickr
                                v-model="filterPeriod"
                                id="calendarFlatpickrClients"
                                class=""
                                :config="calendarConfig"
                                ref="datePickerRef"
                              ></flat-pickr>
                            </div>

                            <div>
                              <hr class="horizontal dark my-2" />
                            </div>
                            <div class="d-flex justify-content-between">
                              <a
                                @click="resetDate"
                                data-bs-close="dropdown"
                                class="dropdown-item border-radius-md text-danger text-center"
                                href="javascript:"
                                >Без фільтру</a
                              >
                              <a
                                @click="setNewFilter('date', filterPeriod)"
                                data-bs-close="dropdown"
                                class="dropdown-item border-radius-md fc-button-primary text-center"
                                href="javascript:"
                                >Обрати дати</a
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="company.crmMode !== 'hotel'"
                          class="dropdown d-inline"
                        >
                          <a
                            href="javascript:"
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            <span v-if="filters.service">{{
                              filters.service.name
                            }}</span>
                            <span v-else>Послуга</span>
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li v-for="item of serviceItems" :key="item.id">
                              <a
                                @click="setNewFilter('service', item)"
                                class="dropdown-item border-radius-md"
                                href="javascript:"
                                >{{ item.name }}</a
                              >
                            </li>
                            <li>
                              <hr class="horizontal dark my-2" />
                            </li>
                            <li>
                              <a
                                @click="setNewFilter('service', null)"
                                class="dropdown-item border-radius-md text-danger"
                                href="javascript:"
                                >Без фільтру</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown d-inline">
                          <a
                            href="javascript:"
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            <template v-if="filters.staff"
                              >{{ filters.staff.name }}
                            </template>
                            <span v-else
                              ><template v-if="company.crmMode !== 'hotel'"
                                >Майстер</template
                              >
                              <template v-else>Номер</template></span
                            >
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li v-for="item of staffItems" :key="item.id">
                              <a
                                @click="setNewFilter('staff', item)"
                                class="dropdown-item border-radius-md"
                                href="javascript:"
                                >{{ item.name }}</a
                              >
                            </li>

                            <li>
                              <hr class="horizontal dark my-2" />
                            </li>
                            <li>
                              <a
                                @click="setNewFilter('staff', null)"
                                class="dropdown-item border-radius-md text-danger"
                                href="javascript:"
                                >Без фільтру</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown d-inline">
                          <a
                            href="javascript:"
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            <template v-if="filters.status">
                              <span v-if="filters.status === 'new'">Нова</span>
                              <span v-if="filters.status === 'done'"
                                >Клієнт прийшов</span
                              >
                              <span v-if="filters.status === 'cancelled'"
                                >Відмінено</span
                              >
                              <span
                                v-if="
                                  filters.status === 'personal' &&
                                  company.crmMode === 'hotel'
                                "
                                >Особиста</span
                              >
                            </template>
                            <span v-else> Статус </span>
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li v-for="item of statusItems" :key="item">
                              <a
                                @click="setNewFilter('status', item)"
                                class="dropdown-item border-radius-md"
                                href="javascript:"
                                ><span v-if="item === 'new'">Нова</span>
                                <span v-if="item === 'done'"
                                  >Клієнт прийшов</span
                                >
                                <span v-if="item === 'cancelled'"
                                  >Відмінено</span
                                >
                                <span v-if="item === 'personal'"
                                  >Особиста</span
                                ></a
                              >
                            </li>
                            <li>
                              <hr class="horizontal dark my-2" />
                              <a
                                @click="setNewFilter('status', null)"
                                class="dropdown-item border-radius-md text-danger"
                                href="javascript:"
                                >Без фільтру</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown d-inline">
                          <a
                            href="javascript:"
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            <span v-if="!filters.payment">Оплата</span>
                            <span v-else>{{ filters.payment.label }}</span>
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li>
                              <a
                                v-for="item of paymentItems"
                                :key="item.value"
                                @click="setNewFilter('payment', item)"
                                class="dropdown-item border-radius-md"
                                href="javascript:"
                                >{{ item.label }}</a
                              >
                            </li>
                            <li>
                              <hr class="horizontal dark my-2" />
                            </li>
                            <li>
                              <a
                                @click="setNewFilter('payment', null)"
                                class="dropdown-item border-radius-md text-danger"
                                href="javascript:"
                                >Без фільтру</a
                              >
                            </li>
                          </ul>
                        </div>
                        <div class="dropdown d-inline">
                          <a
                            href="javascript:"
                            class="btn btn-outline-dark dropdown-toggle btn-sm d-flex justify-content-between align-items-center"
                            data-bs-toggle="dropdown"
                            id="navbarDropdownMenuLink2"
                          >
                            <span
                              v-if="
                                filters.source?.value === 'clientWebForm' &&
                                company.crmMode === 'hotel'
                              "
                              >Oнлайн бронь</span
                            >
                            <span
                              v-else-if="
                                filters.source?.value === 'clientWebForm' &&
                                company.crmMode !== 'hotel'
                              "
                              >Онлайн запис</span
                            >
                            <span v-else-if="filters.source?.value">{{
                              filters.source.label
                            }}</span>
                            <span v-else> Джерело </span>
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                            aria-labelledby="navbarDropdownMenuLink2"
                            data-popper-placement="left-start"
                          >
                            <li v-for="item of sourceItems" :key="item.value">
                              <a
                                @click="setNewFilter('source', item)"
                                class="dropdown-item border-radius-md"
                                href="javascript:"
                              >
                                <span
                                  v-if="
                                    item.value === 'clientWebForm' &&
                                    company.crmMode === 'hotel'
                                  "
                                  >Онлайн бронь</span
                                >
                                <span
                                  v-if="
                                    item.value === 'clientWebForm' &&
                                    company.crmMode !== 'hotel'
                                  "
                                  >Онлайн запис
                                </span>

                                <span v-if="item.value === 'adminWebApp'"
                                  >Адмін панель</span
                                >
                              </a>
                            </li>
                            <li>
                              <hr class="horizontal dark my-2" />
                              <a
                                @click="setNewFilter('source', null)"
                                class="dropdown-item border-radius-md text-danger"
                                href="javascript:"
                                >Без фільтру</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </FilterAside>
            <div v-if="!isMobile" class="col-sm-12">
              <div class="dropdown d-inline">
                <a
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                  data-bs-auto-close="true"
                >
                  <template v-if="filters.date">{{ filters.date }}</template>
                  <template v-else> Період дат</template>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3 mx-1"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <div @click.prevent.stop="">
                    <flat-pickr
                      v-model="filterPeriod"
                      id="calendarFlatpickrClients"
                      class=""
                      :config="calendarConfig"
                      ref="datePickerRef"
                    ></flat-pickr>
                  </div>

                  <div>
                    <hr class="horizontal dark my-2" />
                  </div>
                  <div class="d-flex justify-content-between">
                    <a
                      @click="resetDate"
                      data-bs-close="dropdown"
                      class="dropdown-item border-radius-md text-danger text-center mx-1"
                      href="javascript:"
                      >Без фільтру</a
                    >
                    <a
                      @click="setNewFilter('date', filterPeriod)"
                      data-bs-close="dropdown"
                      class="dropdown-item border-radius-md fc-button-primary text-center"
                      href="javascript:"
                      >Обрати дати</a
                    >
                  </div>
                </div>
              </div>
              <div v-if="company.crmMode !== 'hotel'" class="dropdown d-inline">
                <a
                  href="javascript:"
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                >
                  <span v-if="filters.service">{{ filters.service.name }}</span>
                  <span v-else>Послуга </span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <li v-for="item of serviceItems" :key="item.id">
                    <a
                      @click="setNewFilter('service', item)"
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                      >{{ item.name }}</a
                    >
                  </li>
                  <li>
                    <hr class="horizontal dark my-2" />
                  </li>
                  <li>
                    <a
                      @click="setNewFilter('service', null)"
                      class="dropdown-item border-radius-md text-danger"
                      href="javascript:"
                      >Без фільтру</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown d-inline">
                <a
                  href="javascript:"
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                >
                  <template v-if="filters.staff"
                    >{{ filters.staff.name }}
                  </template>
                  <span v-else
                    ><template v-if="company.crmMode !== 'hotel'"
                      >Майстер</template
                    >
                    <template v-else>Номер</template></span
                  >
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3 mx-1"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <li v-for="item of staffItems" :key="item.id">
                    <a
                      @click="setNewFilter('staff', item)"
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                      >{{ item.name }}</a
                    >
                  </li>

                  <li>
                    <hr class="horizontal dark my-2" />
                  </li>
                  <li>
                    <a
                      @click="setNewFilter('staff', null)"
                      class="dropdown-item border-radius-md text-danger"
                      href="javascript:"
                      >Без фільтру</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown d-inline">
                <a
                  href="javascript:"
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                >
                  <template v-if="filters.status">
                    <span v-if="filters.status === 'new'">Нова</span>
                    <span
                      v-if="
                        filters.status === 'done' && company.crmMode !== 'hotel'
                      "
                      >Клієнт прийшов</span
                    >
                    <span
                      v-if="
                        filters.status === 'done' && company.crmMode === 'hotel'
                      "
                      >Клієнт відвідав</span
                    >
                    <span v-if="filters.status === 'cancelled'">Відмінено</span>
                    <span v-if="filters.status === 'personal'">Особиста</span>
                  </template>
                  <span v-else> Статус </span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <li v-for="item of statusItems" :key="item">
                    <a
                      @click="setNewFilter('status', item)"
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                      ><span v-if="item === 'new'">Нова</span>
                      <span
                        v-if="item === 'done' && company.crmMode !== 'hotel'"
                        >Клієнт прийшов</span
                      >
                      <span
                        v-if="item === 'done' && company.crmMode === 'hotel'"
                        >Клієнт відвідав</span
                      >
                      <span v-if="item === 'cancelled'">Відмінено</span>
                      <span v-if="item === 'personal'">Особиста</span></a
                    >
                  </li>
                  <li>
                    <hr class="horizontal dark my-2" />
                    <a
                      @click="setNewFilter('status', null)"
                      class="dropdown-item border-radius-md text-danger"
                      href="javascript:"
                      >Без фільтру</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown d-inline">
                <a
                  href="javascript:"
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                >
                  <span v-if="!filters.payment">Оплата</span>
                  <span v-else>{{ filters.payment.label }}</span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <li>
                    <a
                      v-for="item of paymentItems"
                      :key="item.value"
                      @click="setNewFilter('payment', item)"
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                      >{{ item.label }}</a
                    >
                  </li>
                  <li>
                    <hr class="horizontal dark my-2" />
                  </li>
                  <li>
                    <a
                      @click="setNewFilter('payment', null)"
                      class="dropdown-item border-radius-md text-danger"
                      href="javascript:"
                      >Без фільтру</a
                    >
                  </li>
                </ul>
              </div>
              <div class="dropdown d-inline">
                <a
                  href="javascript:"
                  class="btn btn-outline-dark dropdown-toggle btn-sm mx-1"
                  data-bs-toggle="dropdown"
                  id="navbarDropdownMenuLink2"
                >
                  <span
                    v-if="
                      filters.source?.value === 'clientWebForm' &&
                      company.crmMode === 'hotel'
                    "
                    >Oнлайн бронь</span
                  >
                  <span
                    v-else-if="
                      filters.source?.value === 'clientWebForm' &&
                      company.crmMode !== 'hotel'
                    "
                    >Онлайн запис</span
                  >
                  <span v-else-if="filters.source?.value">{{
                    filters.source.label
                  }}</span>
                  <span v-else> Джерело </span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                  aria-labelledby="navbarDropdownMenuLink2"
                  data-popper-placement="left-start"
                >
                  <li v-for="item of sourceItems" :key="item.value">
                    <a
                      @click="setNewFilter('source', item)"
                      class="dropdown-item border-radius-md"
                      href="javascript:"
                    >
                      <span
                        v-if="
                          item.value === 'clientWebForm' &&
                          company.crmMode === 'hotel'
                        "
                        >Онлайн бронь</span
                      >
                      <span
                        v-if="
                          item.value === 'clientWebForm' &&
                          company.crmMode !== 'hotel'
                        "
                        >Онлайн запис
                      </span>

                      <span v-if="item.value === 'adminWebApp'"
                        >Адмін панель</span
                      >
                    </a>
                  </li>
                  <li>
                    <hr class="horizontal dark my-2" />
                    <a
                      @click="setNewFilter('source', null)"
                      class="dropdown-item border-radius-md text-danger"
                      href="javascript:"
                      >Без фільтру</a
                    >
                  </li>
                </ul>
              </div>
              <div class="d-inline flex-column float-end">
                <a
                  class="btn btn-sm mx-1"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsDowloadCsv.modalId}`"
                >
                  <img
                    :src="require(`@/assets/icons/DownloadCsv.png`)"
                    width="24"
                    height="24"
                    alt="download - csv"
                  />
                </a>
                <BmnCustomPopup
                  :params="modalParamsDowloadCsv"
                ></BmnCustomPopup>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div class="px-0 pb-0 card-body">
    <div class="table-responsive">
      <vue-good-table
        mode="remote"
        :pagination-options="paginationOptions"
        :sort-options="sortOptions"
        :totalRows="tableData.totalRecords"
        :columns="datatableColumns"
        :rows="filteredData"
        v-model:isLoading="isLoading"
        v-on:page-change="onPageChange"
        v-on:sort-change="onSortChange"
        v-on:column-filter="onColumnFilter"
        v-on:per-page-change="onPerPageChange"
        v-on:row-click="onRowClick"
        theme="default"
        styleClass="vgt-table bordered"
        responsive
        compactMode
        :rowStyleClass="getClassRowTotal"
      >
        <template #table-row="props">
          <div v-if="props.column.field === 'sequenceId'">
            <div class="row">
              <div class="col-12">
                <span class="text-xs">{{ props.row.sequenceId }}</span>
              </div>
            </div>
          </div>
          <div v-if="props.column.field === 'createdAt'">
            <div class="row">
              <div class="col-12">
                <span class="text-xs">{{
                  timestampToString(props.row.createdAt)
                }}</span>
              </div>
            </div>
          </div>

          <div class="row" v-if="props.column.field === 'clientId'">
            <div
              class="col-12"
              v-for="(client, index) in props.row.clients"
              :key="index"
            >
              <span class="text-xs"
                >{{ client.clientId?.firstName }}
                {{ client.clientId?.lastName }}</span
              ><br />
              <span class="text-xs">{{ client.clientId?.phoneNumber }}</span>
            </div>
          </div>
          <div v-if="props.column.field === 'services'">
            <div
              class="text-xs"
              v-for="(service, index) in props.row.services"
              :key="index"
            >
              {{ service.serviceId?.name }} ({{
                displayDuration(service.serviceId?.duration)
              }})
            </div>
            <div
              class="my-2 text-xs font-weight-bold"
              v-if="
                props.row.customTotalDuration !== null &&
                props.row.customTotalDuration !== undefined
              "
            >
              Тривалість:
              {{ displayDuration(props.row.customTotalDuration) }}
            </div>
          </div>

          <div v-if="props.column.field === 'staffId'">
            <span class="my-2 text-xs"
              >{{ props.row.staffId?.name }} ({{
                positionsMap[props.row.staffId?.positionId]?.name ||
                props.row.staffId?.positionId
              }})</span
            >
          </div>

          <div v-if="props.column.fieldId === 'serviceDateTime'">
            {{ this.tableFieldServiceDateTime(props.row) }}
          </div>
          <div v-if="props.column.fieldId === 'serviceDateTimeFrom'">
            {{ this.tableFieldServiceDateTimeFrom(props.row) }}
          </div>
          <div v-if="props.column.fieldId === 'serviceDateTimeTo'">
            {{ this.tableFieldServiceDateTimeTo(props.row) }}
          </div>

          <div v-if="props.column.field === 'isConfirmed'">
            <span v-if="props.row.status === 'personal'"></span>
            <span v-else-if="props.row.isConfirmed">Підтверджено</span>
            <span v-else-if="!props.row.isConfirmed">Не підтверджено</span>
            <span class="my-2 text-xs"></span>
          </div>

          <div class="text-start" v-if="props.column.field === 'status'">
            <div class="badge badge-dot" v-if="props.row.status === 'new'">
              <i class="bg-info"></i>
              <span class="text-dark text-xs">Нова</span>
            </div>
            <div class="badge badge-dot" v-if="props.row.status === 'done'">
              <i class="bg-success"></i>
              <span class="text-dark text-xs" v-if="company.crmMode !== 'hotel'"
                >Клієнт прийшов</span
              >
              <span class="text-dark text-xs" v-if="company.crmMode === 'hotel'"
                >Клієнт відвідав</span
              >
            </div>
            <div
              class="badge badge-dot"
              v-if="props.row.status === 'cancelled'"
            >
              <i class="bg-danger"></i>
              <span class="text-dark text-xs">Відмінено</span>
            </div>
            <div
              class="badge bg-gradient-dark label-personal"
              v-if="
                props.row.status === 'personal' && company.crmMode !== 'hotel'
              "
            >
              Особиста
            </div>
            <div
              class="badge bg-gradient-dark label-personal"
              v-if="
                props.row.status === 'personal' && company.crmMode === 'hotel'
              "
            >
              Закриті дати
            </div>
          </div>

          <div v-if="props.column.fieldId === 'fixedFinalPrice'">
            <span v-if="props.row.status === 'personal'"></span>
            <span v-else>{{
              this.tableFieldServicesTotalPrice(props.row)
            }}</span>
          </div>
          <div v-if="props.column.field === 'paidAmount'">
            <div class="row">
              {{ props.row.paidAmount }}
            </div>
          </div>

          <div v-if="props.column.field === 'discountAmount'">
            <div class="row">
              {{ props.row.discountAmount }}
            </div>
          </div>

          <div v-if="props.column.field === 'stillToPay'">
            <div class="row">
              {{
                props.row.servicesCost +
                  props.row.additionalServicesCost -
                  props.row.discountAmount -
                  props.row.paidAmount +
                  props.row.refundedAmount || 0
              }}
            </div>
          </div>

          <div v-if="props.column.field === 'payments'">
            <div
              class="text-xs"
              v-for="(payment, index) in props.row.payments"
              :key="index"
            >
              <span v-if="payment.paidAt">
                {{ showDate(payment.paidAt) }}
                ({{ showPaymentType(payment.operationType) }})
              </span>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import Spinner from "@/views/shared/Spinner.vue";
import api from "@/services/api";
import timeDisplay from "@/utils/timeDisplay";
import constants from "@/constants";
import { useTable } from "@/composables/good-table";
import { computed, ref } from "vue";
import { Ukrainian } from "flatpickr/dist/l10n/uk";

import flatPickr from "vue-flatpickr-component";
import FilterAside from "@/components/FilterAside.vue";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

export default {
  setup() {
    const isOpenFilter = ref(false);
    const {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      tableData,
      tablePerPage,
      paginationOptions,
      sortOptions,
      isLoading,
    } = useTable(api.serviceOrders.list, "service-orders-datatables");
    const { isMobile } = useBreakpoints();

    const filters = ref({
      date: null,
      status: false,
      staff: null,
      payment: null,
      service: null,
      source: null,
    });
    const isFiltered = computed(() => {
      return (
        filters.value.date ||
        filters.value.status ||
        filters.value.service ||
        filters.value.staff ||
        filters.value.source ||
        filters.value.payment
      );
    });
    const copyData = computed(() => {
      return tableData.value?.rows.map((item) => {
        let paidStatus = "none";
        if (item.paidAmount >= item.servicePrice) {
          paidStatus = "fully";
        } else if (item.paidAmount > 0) {
          paidStatus = "part";
        }
        return { ...item, paidStatus: paidStatus };
      });
    });

    const filteredData = computed(() => {
      return filterData(copyData, filters);
    });

    const statusItems = computed(() => {
      return [...new Set(tableData.value.rows.map((item) => item.status))];
    });

    const staffItems = computed(() => {
      const uniqueItems = tableData.value.rows.reduce((result, item) => {
        if (!result.some((staff) => staff.id === item.staffId._id)) {
          result.push({
            id: item.staffId._id,
            name: item.staffId.name,
          });
        }
        return result;
      }, []);

      return uniqueItems;
    });
    const serviceItems = computed(() => {
      const uniqueItems = tableData.value.rows.reduce((result, item) => {
        item.services?.forEach((itemService) => {
          if (
            !result.some((service) => service.id === itemService.serviceId._id)
          ) {
            result.push({
              id: itemService.serviceId._id,
              name: itemService.serviceId.name,
            });
          }
        });
        return result;
      }, []);

      return uniqueItems;
    });

    const paymentItems = ref([
      { label: "Повністю оплачено", value: "fully" },
      {
        label: "Частково оплачено",
        value: "part",
      },
      { label: "Не оплачено", value: "none" },
    ]);
    const sourceItems = ref([
      { label: "", value: "clientWebForm" },
      { label: "Адмін панель", value: "adminWebApp" },
    ]);

    const datePickerRef = ref(null);
    const calendarConfig = ref({
      mode: "range",
      dateFormat: "Y-m-d",
      locale: Ukrainian,
      inline: true,
      disableMobile: true,
    });
    const filterPeriod = ref(null);
    const dateArray = computed(() => {
      if (filterPeriod.value) {
        const arr = filterPeriod.value.split(" to ");
        if (arr.length > 1) {
          return {
            from: DateTime.fromISO(arr[0]).toISODate(),
            to: DateTime.fromISO(arr[1]).toISODate(),
          };
        } else {
          return { from: DateTime.fromISO(arr[0]).toISODate(), to: "" };
        }
      } else {
        return { from: "", to: "" };
      }
    });

    function resetDate() {
      datePickerRef.value.fp.clear();
      setNewFilter("date", null);
    }

    function isInPeriod(dateValue) {
      const date = DateTime.fromISO(dateValue).toISODate();

      if (dateArray.value.from && dateArray.value.to) {
        if (date >= dateArray.value.from && date <= dateArray.value.to) {
          return true;
        } else {
          return false;
        }
      } else if (dateArray.value.from) {
        if (date !== dateArray.value.from) {
          return false;
        } else {
          return true;
        }
      }
    }

    function filterData(data, filters) {
      return data.value?.filter((item) => {
        return (
          (!filters.value.status || item.status === filters.value.status) &&
          (!filters.value.staff ||
            item.staffId._id === filters.value.staff.id) &&
          (!filters.value.payment ||
            item.paidStatus === filters.value.payment.value) &&
          (!filters.value.source ||
            item.createdSource === filters.value.source.value) &&
          (!filters.value.service ||
            item.services?.some(
              (itemService) =>
                itemService.serviceId._id === filters.value.service.id
            )) &&
          (!filters.value.date || isInPeriod(item.createdAt))
        );
      });
    }

    function setNewFilter(name, newValue) {
      filters.value[name] = newValue;
    }

    return {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      setNewFilter,
      resetDate,
      filterPeriod,
      calendarConfig,
      datePickerRef,
      isLoading,
      paymentItems,
      isOpenFilter,
      dateArray,
      isFiltered,
      copyData,
      filteredData,
      isMobile,
      filters,
      paginationOptions,
      statusItems,
      serviceItems,
      staffItems,
      sortOptions,
      sourceItems,
      tableData,
      tablePerPage,
      filterData,
    };
  },

  name: "service-orders-list",

  components: {
    FilterAside,
    BmnCustomPopup,

    flatPickr,
  },

  created() {},

  async mounted() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    this.company = await api.company.show();

    const staffColumn = this.datatableColumns?.find((column) => {
      return column.field === "staffId";
    });
    if (staffColumn) {
      staffColumn.label = this.getLabelStaff();
    }

    if (this.company.crmMode === "hotel") {
      // hide "services" column
      const column = this.datatableColumns.find((item) => {
        return item.field === "services";
      });
      if (column) {
        column.hidden = true;
      }
      const columnDate = this.datatableColumns.find((item) => {
        return item.field === "serviceDate";
      });
      if (columnDate) {
        columnDate.hidden = true;
      }
    }
    if (this.company.crmMode !== "hotel") {
      // hide "services" column
      const columnDateFrom = this.datatableColumns.find((item) => {
        return item.field === "serviceDateFrom";
      });
      const columnDateTo = this.datatableColumns.find((item) => {
        return item.field === "serviceDateTo";
      });
      if (columnDateFrom) {
        columnDateFrom.hidden = true;
      }
      if (columnDateTo) {
        columnDateTo.hidden = true;
      }
    }
    const services = await api.services.list({
      isActive: 1,
    });

    this.showGroupServiceOrderButton = services.some((service) => {
      return service.groupEnabled;
    });

    const positions = await api.positions.list();
    positions.forEach((position) => {
      this.positionsMap[position._id] = position;
    });

    setTimeout(async () => {
      await this.loadItems();
      this.dataLoaded = true;
    }, 500);
  },

  data() {
    return {
      dataLoaded: false,
      company: {
        crmMode: null,
        localizationMode: null,
      },

      showGroupServiceOrderButton: false,

      positionsMap: {},

      datatableColumns: [
        {
          label: "#",
          field: "sequenceId",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "80px",
          filterOptions: {
            enabled: true,
            placeholder: "№",
          },
        },
        {
          label: "Створено",
          field: "createdAt",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "110px",
        },
        {
          label: "Дата броні",
          field: "serviceDate",
          fieldId: "serviceDateTime",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Заїзд",
          field: "serviceDateFrom",
          fieldId: "serviceDateTimeFrom",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "110px",
        },
        {
          label: "Виїзд",
          field: "serviceDateTo",
          fieldId: "serviceDateTimeTo",
          sortable: true,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          width: "110px",
        },
        {
          label: "Клієнт",
          field: "clientId",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
          filterOptions: {
            enabled: true,
            placeholder: "Пошук за телефоном",
            // styleClass: "someClass",
            // filterFn: this.columnFilterFn,
            // trigger: 'enter',
          },
          width: "140px",
        },
        {
          label: "Послуга",
          field: "services",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          // hidden: this.company?.crmMode === "hotel",
          width: "140px",
        },
        {
          label: this.getLabelStaff(),
          field: "staffId",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
        {
          label: "Підтвердження",
          field: "isConfirmed",
          sortable: false,
          type: "boolean",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start",
          tdClass: "text-xs font-weight-normal text-start",
          width: "140px",
        },
        {
          label: "Статус",
          field: "status",
          sortable: false,
          type: "boolean",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start",
          tdClass: "text-xs font-weight-normal text-start",
        },
        {
          label: "Сума",
          field: this.tableFieldServicesTotalPrice,
          fieldId: "fixedFinalPrice",
          sortable: false,
          type: "string",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Сплачено",
          field: "paidAmount",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Знижка",
          field: "discountAmount",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "До сплати",
          field: "stillToPay",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Коментар",
          field: "comment",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "ID",
          field: "_id",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Дати оплат",
          field: "payments",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
          width: "140px",
        },
      ],
      modalParamsDowloadCsv: {
        modalId: "modal-download-csv-1",
        modalTitle: "Завантажити бронювання",
        modalDescription:
          "Дані будуть вивантажені відповідно до вказаної фільтрації на сторінці списку Бронювань.",
        modalButtonCancelText: "Скасувати",
        modalButtonConfirmText: "Завантажити",
        confirmButtonColor: "success",
        confirmHandler: async () => {
          try {
            await this.downloadCsv();
            this.$store.commit("addToast", {
              title: "Збережено",
            });
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  methods: {
    getClassRowTotal(row) {
      const value =
        row.servicesCost +
          row.additionalServicesCost -
          row.discountAmount -
          row.paidAmount +
          row.refundedAmount || 0;

      if (value < 0) {
        return "slightly-red-bg";
      }

      return "";
    },
    tableFieldServiceDateTime(row) {
      if (row.serviceDateFrom && row.serviceDateTo) {
        const dateTo = DateTime.fromISO(row.serviceDateTo)
          .plus({ days: 1 })
          .toISODate();
        return `${this.timestampToString(
          row.serviceDateFrom
        )} - ${this.timestampToString(dateTo)}`;
      }
      return `${this.timestampToString(row.serviceDate)} ${row.serviceTime}`;
    },
    tableFieldServiceDateTimeFrom(row) {
      if (row.serviceDateFrom && row.serviceDateTo) {
        const dateTo = DateTime.fromISO(row.serviceDateTo)
          .plus({ days: 1 })
          .toISODate();
        return `${this.timestampToString(row.serviceDateFrom)}`;
      }
      return `${this.timestampToString(row.serviceDate)} ${row.serviceTime}`;
    },
    tableFieldServiceDateTimeTo(row) {
      if (row.serviceDateFrom && row.serviceDateTo) {
        const dateTo = DateTime.fromISO(row.serviceDateTo)
          .plus({ days: 1 })
          .toISODate();
        return `${this.timestampToString(dateTo)}`;
      }
      return `${this.timestampToString(row.serviceDate)} ${row.serviceTime}`;
    },
    tableFieldServicesTotalPrice(row) {
      if (row.fixedFinalPrice !== row.finalPrice) {
        return `${row.finalPrice} -> ${row.fixedFinalPrice}`;
      }

      return row.fixedFinalPrice;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },

    displayDuration(minutes) {
      return timeDisplay.minutesToHumanFormat(minutes);
    },

    showDate(isoDate) {
      if (!isoDate) {
        return "";
      }
      return DateTime.fromISO(isoDate)
        .setLocale("uk-UA")
        .toFormat("dd.MM.yyyy");
    },

    showPaymentType(operationType) {
      if (operationType === "cash") {
        return "готівка";
      }
      if (operationType === "card-transfer") {
        return "картка";
      }
      if (operationType === "monobank-acquiring") {
        return "monopay";
      }
      if (operationType === "liqpay-acquiring") {
        return "liqpay";
      }
    },

    getLabelStaff() {
      const localizationMode = this.company?.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Ресурс";
        case localizationMode === "hotel":
          return "Номер";
        default:
          return "Майстер";
      }
    },

    async downloadCsv() {
      const apiFilters = Object.entries(this.filters).reduce(
        (r, [key, value]) => {
          if (value === null) {
            return r;
          }

          if (key === "staff") {
            r["staffId"] = value.id;
            return r;
          }

          if (key === "status" && typeof value === "boolean") {
            return r;
          }

          if (["payment", "source"].includes(key)) {
            r[key] = value.value;
            return r;
          }

          if (key === "date") {
            const arr = value.split(" to ");
            if (arr.length > 1) {
              r["serviceDateFrom"] = arr[0];
              r["serviceDateTo"] = arr[1];
            } else {
              r["serviceDate"] = value;
            }

            return r;
          }

          r[key] = value;
          return r;
        },
        {
          type: "csv",
        }
      );

      const response = await api.serviceOrders.downloadCsv(apiFilters);
      const blob = await response.blob();
      var downloadUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = "bookmenow_orders.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    },
  },
};
</script>
<style scoped>
.badge.label-personal {
  background-image: none;
  background-color: #fbe6cf !important;
  color: grey;
}

.flatpickr-calendar.inline {
  box-shadow: none !important;
  border: none !important;
}

.flatpickr-input[readonly] {
  display: none;
}

.clientImage {
  border-radius: 50%;
}

.material-icons.blue {
  color: var(--bs-primary);
}

.imageBlock {
  padding-left: 0px;
}
</style>
