import * as _ from "lodash";

import config from "@/config";
const API_URL_BASE = config.API_URL_BASE;

const METHOD_GET = "GET";
const METHOD_POST = "POST";
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";

const OPTIONS = {
  method: null,
  headers: {
    "content-type": "application/json",
    "x-access-token": null,
  },
  body: null,
};

const prepareGetParameters = (params) => {
  const queryParamsArray = [];
  if (_.isPlainObject(params)) {
    _.forEach(params, (value, key) => {
      const encodedKey = encodeURIComponent(key.toString());
      const encodedValue = encodeURIComponent(value);
      queryParamsArray.push(`${encodedKey}=${encodedValue}`);
    });
  }
  const queryParamsString =
    queryParamsArray.length > 0 ? queryParamsArray.join("&") : "";

  return queryParamsString;
};

const getOptions = (method, payload) => {
  const copy = _.cloneDeep(OPTIONS);
  copy.method = method;
  copy.headers["x-access-token"] = localStorage.getItem("accessToken");
  copy.body = JSON.stringify(payload);
  return copy;
};

const ANALYTICS_PATH = "ecommerce/analytics";
const analytics = {
  list: async (payload) => {
    const API_URL = `${API_URL_BASE}/${ANALYTICS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },

  getStaffLoad: async (payload) => {
    const API_URL = `${API_URL_BASE}/${ANALYTICS_PATH}/staffLoad`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },

  getServiceOrders: async (payload) => {
    const API_URL = `${API_URL_BASE}/${ANALYTICS_PATH}/serviceOrders`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
};

const BRANCHES_PATH = "settings/branches";
const branches = {
  list: async () => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  isAcquiringEnabled: async () => {
    const API_URL = `${API_URL_BASE}/${BRANCHES_PATH}/isAcquiringEnabled`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
};

const CLIENTS_PATH = "ecommerce/clients";
const clients = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${CLIENTS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${CLIENTS_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${CLIENTS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${CLIENTS_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${CLIENTS_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const executeFetch = async (API_URL, options) => {
  const response = await fetch(API_URL, options);

  if (response.status === 401) {
    return {};
  }

  if (
    response.status === 403 &&
    ![
      "/authentication/signin",
      "/authentication/signup",
      "/authentication/logout",
    ].includes(window.location.pathname)
  ) {
    // window.location.href = "/authentication/signin";
    // await this.$router.push("/authentication/logout");

    localStorage.removeItem("accessToken");
    localStorage.removeItem("app.companyName");
    localStorage.removeItem("localUser");
    localStorage.removeItem("showDevFeatures");

    // window.location.href = "/authentication/logout?reason=jwtExpired";
    window.location.href = "/authentication/logout";
    return;
  }

  if (response.status === 403) {
    return {};
  }

  // if (
  //   response?.headers?.get("x-bmn-force-payment-enabled") === "1" &&
  //   window.location.pathname !== "/subscription"
  // ) {
  //   window.location.href = "/subscription";
  //   return {};
  // }

  if (response.status >= 400) {
    // throw new Error("fetch error");
    const customError = new Error("fetch error");
    // customError.customDesc = "Така послуга вже існує";
    throw customError;
  }

  return await response.json();
};

const COMPANY_PATH = "settings/company";
const company = {
  show: async () => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  update: async (payload) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },

  sendInstantMessage: async (sender, recipients, text, purpose) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/sendInstantMessage`;
    const options = getOptions(METHOD_POST, {
      sender,
      recipients,
      text,
      purpose,
    });
    return await fetch(API_URL, options); // (!) not used `executeFetch` wrapper
  },

  renderInvoiceMesageTemplate: async (orderId, paymentId) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/renderInvoiceMesageTemplate`;
    const options = getOptions(METHOD_POST, { orderId, paymentId });
    return await executeFetch(API_URL, options);
  },

  getInstantMessageStatuses: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/instantMessageStatuses?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  getInstantMessageTopUps: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/instantMessageTopUps?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  instantMessageTopUp: async (payload) => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/instantMessageTopUp`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },

  getInstantMessageBalance: async () => {
    const API_URL = `${API_URL_BASE}/${COMPANY_PATH}/instantMessageBalance`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
};

const MANAGERS_PATH = "settings/managers";
const managers = {
  list: async () => {
    const API_URL = `${API_URL_BASE}/${MANAGERS_PATH}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${MANAGERS_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${MANAGERS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${MANAGERS_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${MANAGERS_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const MYPROFILE_PATH = "myprofile";
const myprofile = {
  show: async () => {
    const API_URL = `${API_URL_BASE}/${MYPROFILE_PATH}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  getAvailableTenants: async () => {
    const API_URL = `${API_URL_BASE}/${MYPROFILE_PATH}/getAvailableTenants`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  switchTenant: async (payload) => {
    const API_URL = `${API_URL_BASE}/${MYPROFILE_PATH}/switchTenant`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  update: async (payload) => {
    const API_URL = `${API_URL_BASE}/${MYPROFILE_PATH}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  remove: async () => {
    const API_URL = `${API_URL_BASE}/${MYPROFILE_PATH}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const MAINTENANCE_PATH = "maintenance";
const maintenance = {
  getMigrations: async () => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/getMigrations`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  runMigrations: async () => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/runMigrations`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  runCronScript: async (name) => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/${name}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  getTenants: async () => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/getTenants`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  removeTenant: async (id) => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/removeTenant/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },

  getMonobankSubmerchants: async () => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/getMonobankSubmerchants`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  setTenantSubscriptionStatus: async (id, subscriptionStatus) => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/setTenantSubscriptionStatus/${id}`;
    const options = getOptions(METHOD_POST, { subscriptionStatus });
    return await executeFetch(API_URL, options);
  },

  checkServiceOrdersForPrepayment: async () => {
    const API_URL = `${API_URL_BASE}/${MAINTENANCE_PATH}/checkServiceOrdersForPrepayment`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
};

const POSITIONS_PATH = "settings/positions";
const positions = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  sort: async (payload) => {
    const API_URL = `${API_URL_BASE}/${POSITIONS_PATH}/sort`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
};

const HOTEL_DISCOUNTS_PATH = "settings/hotelDiscounts";
const hotelDiscounts = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  sort: async (payload) => {
    const API_URL = `${API_URL_BASE}/${HOTEL_DISCOUNTS_PATH}/sort`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
};

const SERVICES_PATH = "settings/services";
const services = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  sort: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}/sort`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const ADDITIONAL_SERVICES_PATH = "settings/additional-service";
const additionalServices = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  show: async (id) => {
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  update: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  delete: async (id) => {
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  sort: async (payload) => {
    const API_URL = `${API_URL_BASE}/${ADDITIONAL_SERVICES_PATH}/sort`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
};

const FEEDBACK_ATTRIBUTE_PATH = "settings/feedback-attribute";
const feedbackAttributes = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${FEEDBACK_ATTRIBUTE_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  show: async (id) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_ATTRIBUTE_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_ATTRIBUTE_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  update: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_ATTRIBUTE_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  delete: async (id) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_ATTRIBUTE_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const FEEDBACK_PATH = "feedback";
const feedbacks = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${FEEDBACK_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  show: async (id) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  update: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${FEEDBACK_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
};

const SERVICE_ORDERS_PATH = "ecommerce/service-orders";
const serviceOrders = {
  getConfig: async () => {
    const currentTimestamp = Date.now();
    const queryString = `currentTimestamp=${currentTimestamp}`;
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/config?${queryString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  getAvailableDates: async (staffId, serviceOrderId) => {
    let queryString = `staffId=${staffId}`;
    if (serviceOrderId) {
      queryString = `${queryString}&serviceOrderId=${serviceOrderId}`;
    }
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/available-dates?${queryString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  getFreeSlots: async (date, staffId, duration, currentOrderId) => {
    const queryString = `date=${date}&staffId=${staffId}&duration=${duration}&currentOrderId=${currentOrderId}`;
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/free-slots?${queryString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  downloadCsv: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    options.responseType = "blob";

    return fetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },

  setFixedFinalPrice: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/setFixedFinalPrice`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },

  addDiscount: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/addDiscount`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  addDiscountDraft: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/addDiscountDraft`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  removeDiscount: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/removeDiscount`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  addPayment: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/addPayment`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  addPaymentDraft: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/addPaymentDraft`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  removePayment: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/removePayment`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  refundPayment: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/refundPayment`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  getCheckInAndOutInfo: async (date) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/checkInsAndOutInfo?date=${date}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  getPriceBreakdown: async (payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/getPriceBreakdown`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },

  addHistoryUpdate: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${SERVICE_ORDERS_PATH}/${id}/addHistoryUpdate`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
};

const PAYMENTS_PATH = "ecommerce/payments";
const payments = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${PAYMENTS_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },

  addTransactions: async (payload) => {
    const API_URL = `${API_URL_BASE}/${PAYMENTS_PATH}/transactions`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },

  updateTransaction: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${PAYMENTS_PATH}/transaction/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },

  deleteTransaction: async (id) => {
    const API_URL = `${API_URL_BASE}/${PAYMENTS_PATH}/transaction/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const STAFF_PATH = "settings/staff";
const staff = {
  list: async (params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  findOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  sort: async (payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/sort`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  getWorkingHours: async (id, params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}/workingHours?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  getWorkingHoursRange: async (id, params) => {
    const queryParamsString = prepareGetParameters(params);
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}/workingHoursRange?${queryParamsString}`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  saveWorkingHours: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}/saveWorkingHours`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  constructorSaveWorkingHours: async (staffId, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/constructorSaveWorkingHours`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  create: async (payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateOne: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  resetPassword: async (id, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}/resetPassword`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  removeOne: async (id) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  getAdditionalServices: async (staffId) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/getAdditionalServices`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  addAdditionalService: async (staffId, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/createAdditionalService`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateAdditionalService: async (staffId, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/updateAdditionalService/${payload._id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeAdditionalService: async (staffId, id) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/removeAdditionalService/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
  getDefaultAmenities: async (staffId) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/getDefaultAmenities`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  getSyncUrls: async (staffId) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/getSyncUrls`;
    const options = getOptions(METHOD_GET);
    return await executeFetch(API_URL, options);
  },
  addSyncUrl: async (staffId, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/createSyncUrl`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
  updateSyncUrl: async (staffId, payload) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/updateSyncUrl/${payload._id}`;
    const options = getOptions(METHOD_PUT, payload);
    return await executeFetch(API_URL, options);
  },
  removeSyncUrl: async (staffId, id) => {
    const API_URL = `${API_URL_BASE}/${STAFF_PATH}/${staffId}/removeSyncUrl/${id}`;
    const options = getOptions(METHOD_DELETE);
    return await executeFetch(API_URL, options);
  },
};

const USER_PATH = "settings/user";
const user = {
  resetPassword: async (userId, payload) => {
    const API_URL = `${API_URL_BASE}/${USER_PATH}/${userId}/resetPassword`;
    const options = getOptions(METHOD_POST, payload);
    return await executeFetch(API_URL, options);
  },
};

const api = {
  company,
  analytics,
  branches,
  clients,
  hotelDiscounts,
  maintenance,
  managers,
  myprofile,
  positions,
  services,
  additionalServices,
  feedbackAttributes,
  feedbacks,
  serviceOrders,
  payments,
  staff,
  user,
};

export default api;
