<script setup>
import api from "@/services/api";
import Choices from "choices.js";
import Spinner from "@/views/shared/Spinner.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { useTable } from "@/composables/good-table";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

const store = useStore();

const {
  onColumnFilter,
  onPageChange,
  onPerPageChange,
  onSortChange,
  loadItems,
  onRowClick,
  tableData,
  tablePerPage,
  paginationOptions,
  sortOptions,
  isLoading,
} = useTable(api.branches.list);

const dataLoaded = ref(false);
const branches = ref([]);
const showDevFeatures = ref(false);

const inputRef = ref(null);
const mapRef = ref(null);

const DEFAULT_MAP_CENTER = {
  lat: 49.344,
  lng: 32.031,
};

const company = ref({
  name: "",
  address: "",
  addressGoogle: { formatted: "", lat: "", lng: "" },
  phoneNumber: "",
  about: "",
  crmMode: "general",
  localizationMode: "",
  forcePaymentEnabled: false,
  forcePaymentAmount: null,
  forcePaymentUrl: null,
  serviceOrderToSuccessAutomation: true,
});
const center = computed(() => {
  return {
    lat: company.value.addressGoogle.lat,
    lng: company.value.addressGoogle.lng,
  };
});

const companyApiToken = ref("");
const datatableColumns = ref([
  {
    label: "",
    field: "correct",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Адреса",
    field: "address",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
    tdClass: "text-xs font-weight-normal",
  },
  {
    label: "Телефон",
    field: "phone",
    sortable: false,
    thClass:
      "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
    tdClass: "text-xs font-weight-normal",
  },
]);

onBeforeMount(async () => {
  showDevFeatures.value = !!localStorage.getItem("showDevFeatures");
  const result = await api.company.show();
  company.value = { ...company.value, ...result };
  if (result.addressGoogle.formatted) {
    mapOptions.value.center.lat = result.addressGoogle.lat;
    mapOptions.value.center.lng = result.addressGoogle.lng;
    mapOptions.value.zoom = 12;
    googleMarker.value.setOptions({ position: center.value });
    googleMap.value?.setOptions(mapOptions.value);
  }
  companyApiToken.value = "API-TOKEN-PLACEHOLDER";
});
onMounted(async () => {
  setTimeout(autoResizeTextarea, 1000, {
    target: descriptionRef.value,
  });

  setTimeout(() => {
    const selector = document.getElementById("choices-crmMode");
    if (selector) {
      const options = [
        {
          value: "general",
          label: "general",
          selected: "general" === company.value.crmMode,
        },
        {
          value: "hotel",
          label: "hotel",
          selected: "hotel" === company.value.crmMode,
        },
      ];
      if (!company.value.crmMode) {
        options[0].selected = true;
      }
      const choiceApi = new Choices(selector, {
        allowHTML: false,
        removeItemButton: false,
        searchEnabled: false,
        choices: options,
        shouldSort: false,
      });

      company.value.crmMode = choiceApi.getValue().value;
    }
    loadItems();
    dataLoaded.value = true;
  }, 500);

  setTimeout(() => {
    const selector = document.getElementById("choices-localizationMode");
    if (selector) {
      const options = [
        {
          value: "beauty",
          label: "beauty",
          selected: "beauty" === company.value.localizationMode,
        },
        {
          value: "hotel",
          label: "hotel",
          selected: "hotel" === company.value.localizationMode,
        },
        {
          value: "spa",
          label: "spa",
          selected: "spa" === company.value.localizationMode,
        },
      ];
      if (!company.value.localizationMode) {
        options[0].selected = true;
      }
      const choiceApi = new Choices(selector, {
        allowHTML: false,
        removeItemButton: false,
        searchEnabled: false,
        choices: options,
        shouldSort: false,
      });

      company.value.localizationMode = choiceApi.getValue().value;
    }
    loadItems();
    dataLoaded.value = true;
  }, 500);

  await initGoogleMaps();
});

const mapOptions = ref({
  center: { ...DEFAULT_MAP_CENTER },
  mapId: "DEMO_MAP_ID",
  zoom: 6,
});

const descriptionRef = ref(null);

const autocomplete = ref(null);
const googleMap = ref(null);
const googleMarker = ref(null);

watch(autocomplete, (value) => {
  autocomplete.value.addListener("place_changed", () => setPlace());
});

function setPlace() {
  const place = autocomplete.value.getPlace();

  const lat = place.geometry.location.lat();
  const lng = place.geometry.location.lng();
  const formattedAddress = place.formatted_address;

  company.value.addressGoogle.lat = lat;
  company.value.addressGoogle.lng = lng;
  company.value.addressGoogle.formatted = formattedAddress;

  googleMarker.value.setOptions({ position: center.value });

  mapOptions.value.center.lat = lat;
  mapOptions.value.center.lng = lng;
  mapOptions.value.zoom = 12;

  googleMap.value.setOptions(mapOptions.value);
}

async function initGoogleMaps() {
  const loader = new Loader({
    apiKey: "AIzaSyDDL4mb0f6yo-BIXxeEFBJLQWOk4Mh4zk4",
    version: "weekly",
    libraries: ["marker"],
  });
  const center = { lat: -25.344, lng: 131.031 };

  loader.load().then((google) => {
    googleMap.value = new google.maps.Map(mapRef.value, mapOptions.value);

    googleMarker.value = new google.maps.Marker({
      map: googleMap.value,
      position: center,
    });
  });

  const Places = await loader.importLibrary("places");

  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1,
  };

  const options = {
    bounds: defaultBounds,
    types: [],
    componentRestrictions: { country: "ua" },
    fields: ["formatted_address", "geometry.location"],
    strictBounds: false,
  };

  autocomplete.value = new Places.Autocomplete(inputRef.value, options);
}

async function save() {
  try {
    let payload = {
      name: company.value.name,
      address: company.value.address,
      phoneNumber: company.value.phoneNumber,
      about: company.value.about,
      crmMode: company.value.crmMode,
      localizationMode: company.value.localizationMode,
      addressGoogle: company.value?.addressGoogle,
      forcePaymentEnabled: company.value.forcePaymentEnabled,
      forcePaymentAmount: company.value.forcePaymentAmount,
      forcePaymentUrl: company.value.forcePaymentUrl,

      serviceOrderToSuccessAutomation:
        company.value.serviceOrderToSuccessAutomation,
    };

    if (payload.addressGoogle.formatted.trim().length === 0) {
      mapOptions.value.zoom = 6;
      googleMap.value.setOptions(mapOptions.value);
      googleMarker.value.setMap(null);
      payload.addressGoogle.lat = DEFAULT_MAP_CENTER.lat;
      payload.addressGoogle.lng = DEFAULT_MAP_CENTER.lng;
    }
    await api.company.update(payload);

    store.commit("addToast", {
      title: "Збережено",
    });
  } catch (error) {
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  }
}
</script>

<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid my-3 py-3" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-9 mx-auto">
        <!-- Card Basic Info -->
        <div class="card" id="basic-info">
          <div class="card-body pt-0 mt-3">
            <h6>Загальна інформація</h6>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-3">
                <label class="form-label">Назва компанії</label>
                <div class="input-group input-group-outline">
                  <input
                    type="text"
                    class="form-control"
                    v-model="company.name"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-3">
                <label class="form-label">Адреса</label>
                <div class="input-group input-group-outline">
                  <input
                    ref="inputRef"
                    type="text"
                    class="form-control"
                    placeholder=""
                    v-model="company.addressGoogle.formatted"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 mt-3">
                <label class="form-label">Телефон</label>
                <div class="input-group input-group-outline">
                  <input
                    type="tel"
                    class="form-control"
                    minlength="13"
                    maxlength="13"
                    pattern="\+[0-9]{12}"
                    v-model="company.phoneNumber"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 mt-3">
                <label class="form-label">Про компанію</label>
                <div class="input-group input-group-outline">
                  <textarea
                    type="text"
                    rows="2"
                    class="form-control"
                    v-model="company.about"
                    ref="descriptionRef"
                    @input="autoResizeTextarea"
                    style="height: 75px"
                  ></textarea>
                </div>
              </div>
            </div>

            <div ref="mapRef" class="mt-4" id="map"></div>
            <!-- <div class="row mt-4">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div>
                  <label>Посмотреть форму онлайн записи:</label>
                </div>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-sm mb-0 target= _blank"
                >
                  Перейти
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- Card Basic Info finish -->

        <!--  Change serviceOrder status automatically-->
        <div class="card mt-2 pb-3" id="notifyOnCreated">
          <div class="card-header">
            <div class="row">
              <h6>Автоматична зміна статусу броні на "Клієнт відвідав"</h6>
              <div class="w-85">
                <p class="text-sm mb-0">
                  Увімкнувши цю функцію ми будемо автоматично змінювати статус
                  бронювання на наступний день після закінчення бронювання.<br />
                  Зміна статусу відбувається тільки для повністю сплачених
                  бронювань.
                </p>
              </div>
              <div class="w-15 form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="company.serviceOrderToSuccessAutomation"
                />
              </div>
            </div>
          </div>
        </div>
        <!--  Change serviceOrder status automatically finish -->

        <!-- Add branch -->
        <div class="row mt-3" v-if="showDevFeatures">
          <div class="col-12">
            <router-link
              v-if="!tableData.totalRecords"
              class="btn btn-primary"
              to="/settings/branches/new"
              role="button"
            >
              + Філія
            </router-link>
            <!-- TODO: temporarily allow only one branch -->

            <button
              v-if="tableData.totalRecords"
              type="button"
              class="btn bg-gradient-primary"
              :disabled="true"
            >
              + Філія
            </button>
            <div class="card">
              <div class="badge bg-gradient-warning">Super admin</div>
              <!-- Card header -->
              <div class="card-header">
                <h6 class="mb-0">Всі філії</h6>
                <p class="text-sm mb-0">
                  Філія - це фізичне місце, де ваша компанія здійснює послуги.
                  Зазвичай у філій різні адреси, тому коли клієнт бронює послугу
                  то він може обрати по якій адресі він хоче отримати послугу.
                </p>
              </div>
              <vue-good-table
                mode="remote"
                :pagination-options="paginationOptions"
                :sort-options="sortOptions"
                :totalRows="tableData.totalRecords"
                :columns="datatableColumns"
                :rows="tableData.rows"
                v-model:isLoading="isLoading"
                v-on:page-change="onPageChange"
                v-on:sort-change="onSortChange"
                v-on:column-filter="onColumnFilter"
                v-on:per-page-change="onPerPageChange"
                v-on:row-click="onRowClick"
                theme="default"
                styleClass="vgt-table"
                responsive
                compactMode
              >
                <template #table-row="props">
                  <div
                    v-if="props.column.field === 'correct'"
                    class="text-center"
                  ></div>
                  <div v-if="props.column.field === 'address'">
                    {{ props.row.address }}
                  </div>
                  <div v-if="props.column.field === 'phone'">
                    <span>
                      {{ props.row.phoneNumber }}
                    </span>
                  </div>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
        <!-- Add branch finish -->

        <div
          class="card mt-4 pb-3"
          id="phone-confirm-tg"
          v-if="showDevFeatures"
        >
          <div class="badge bg-gradient-warning">Super admin</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>API токен</h6>
              </div>
              <div class="input-group input-group-outline">
                <input
                  type="text"
                  class="form-control"
                  disabled="disabled"
                  v-model="companyApiToken"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Card company settings -->
        <div
          class="card mt-4 pb-3"
          id="phone-confirm-tg"
          v-if="showDevFeatures"
        >
          <div class="badge bg-gradient-warning">Super admin</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Режим CRM</h6>
              </div>
              <div class="col-10">
                <select
                  class="form-control"
                  name="choices-client"
                  id="choices-crmMode"
                  v-model="company.crmMode"
                ></select>
              </div>
              <div class="text-sm mb-0">
                <ul>
                  <li><strong>general</strong> - дефолт</li>
                  <li><strong>hotel</strong> - готель</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Card company settings finish -->

        <div
          class="card mt-4 pb-3"
          id="phone-confirm-tg"
          v-if="showDevFeatures"
        >
          <div class="badge bg-gradient-warning">Super admin</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Режим локалізацій (перекладів)</h6>
              </div>
              <div class="col-10">
                <select
                  class="form-control"
                  name="choices-client"
                  id="choices-localizationMode"
                  v-model="company.localizationMode"
                ></select>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card mt-4 pb-3"
          id="phone-confirm-tg"
          v-if="showDevFeatures"
        >
          <div class="badge bg-gradient-warning">Super admin</div>
          <div class="card-header">
            <div class="row">
              <div class="col-10">
                <h6>Попередження про сплату</h6>
              </div>
              <div class="col-10">
                <div class="w-15 form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="company.forcePaymentEnabled"
                  />
                </div>
              </div>
              <div class="col-10 mt-3" v-if="company.forcePaymentEnabled">
                <div class="input-group input-group-outline">
                  Ціна:
                  <input
                    class="form-control"
                    type="number"
                    v-model="company.forcePaymentAmount"
                  />
                  грн.
                </div>
              </div>
              <div class="col-10 mt-3" v-if="company.forcePaymentEnabled">
                <div class="input-group input-group-outline">
                  Посилання на оплату:
                  <input
                    class="form-control"
                    type="text"
                    v-model="company.forcePaymentUrl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти фiнiш-->
      </div>
    </div>
  </div>
</template>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
#map {
  width: 100%;
  min-height: 300px;
  aspect-ratio: 3;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
